import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Select from 'react-select'

import DatePicker from "react-datepicker";

import Search from 'pages/Parts/Search'
import Pagination from 'pages/Parts/Pagination'

import { PGET, GET, POST } from 'helpers/api'
import { formatDate, formatMoney, formatDateWithTime } from 'helpers/helpers'

function Index() {
	const URL = '/services/admin/api/pos-pageList'
	const { t } = useTranslation()

	const paymentTypes = [
		{ 'id': 1, 'name': t('CASH') },
		{ 'id': 2, 'name': t('MONEY_TRANSFER') },
		{ 'id': 3, 'name': t('TRANSFER') }
	]

	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [modalPayment, setModalPayment] = useState(false)
	const [modalPaymentHistory, setModalPaymentHistory] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [data, setData] = useState([])
	const [payments, setPayments] = useState([])
	const [managerMap, setManagerMap] = useState(new Map());

	function selectItem(item) {
		var itemCopy = { ...item }
		itemCopy.posId = itemCopy.id
		itemCopy.amountIn = ""
		itemCopy.paymentMethod = 3
		itemCopy.monthlyPayment = ""
		itemCopy.paidFrom = ""
		itemCopy.paidUntil = ""
		itemCopy.note = ""
		setSelectedItem(itemCopy)
	}

	async function payment(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }

		var sendData = {}
		sendData.posId = selectedItemCopy.posId
		sendData.amountIn = selectedItemCopy.amountIn
		sendData.paymentMethod = selectedItemCopy.paymentMethod
		sendData.monthlyPayment = selectedItemCopy.monthlyPayment
		sendData.paidFrom = selectedItemCopy.paidFrom
		sendData.paidUntil = selectedItemCopy.paidUntil
		sendData.note = selectedItemCopy.note

		var response = await POST('/services/admin/api/pos-payment', sendData, { loader: true })

		if (response) {
			showPaymentModal(false)
			getData()
		}
	}

	function showPaymentModal(bool) {
		if (!bool) {
			var selectedItemCopy = { ...selectedItem }
			selectedItemCopy.posId = null
			selectedItemCopy.amountIn = ""
			selectedItemCopy.paymentMethod = 3
			selectedItemCopy.note = ''
		}

		if (!selectedItem?.id) {
			toast.warning(t('NO_POS_SELECTED'))
			return
		}

		setModalPayment(bool)
	}

	async function showPaymentHistoryModal(bool, posId) {
		if (!bool) {
			setModalPaymentHistory(bool)
			return;
		}
		const response = await GET('/services/admin/api/pos-payment-history/' + posId, {}, { loader: true })
		setPayments(response)
		setModalPaymentHistory(bool)
	}

	async function getData() {
		const response = await PGET('/services/admin/api/pos-pageList', {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))

			var updatedData = response.data.map(item => {
				if (item.managerId !== 0 && managerMap.has(item.managerId)) {
					return { ...item, managerId: managerMap.get(item.managerId) };
				}else{
					return { ...item, managerId: "admin"};
				}
			});
		
			setData(updatedData);

		}
	}

		
	async function getManagerData() {
		const response = await PGET('/services/uaa/api/manager-allList', {}, { loader: true })
		if (response) {
			const newManagerMap = new Map();
			response.data.forEach(user => {
				if (user.userLogin) {
					if (user.lastName) {
						newManagerMap.set(user.id, user.firstName + " " + user.lastName);
					} else {
						newManagerMap.set(user.id, user.firstName);
					}
				}
			});
			setManagerMap(newManagerMap);
		}
	}

	async function paginate(data) {
		const response = await PGET('/services/admin/api/pos-pageList', { page: data.selected, size: settings.size }, { loader: true })
		var updatedData = response.data.map(item => {
			if (item.managerId !== 0 && managerMap.has(item.managerId)) {
				return { ...item, managerId: managerMap.get("" + item.managerId) };
			}else{
				return { ...item, managerId: "admin"};
			}
		});
		setData(updatedData);
	}

	async function search(data, searchValue) {
		var updatedData = data.map(item => {
			if (item.managerId !== 0 && managerMap.has(item.managerId)) {
				return { ...item, managerId: managerMap.get(item.managerId) };
			}else{
				return { ...item, managerId: "admin"};
			}
		});
		setData(updatedData);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getManagerData()
		getData()
		
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('PAYMENTS')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide me-2" onClick={showPaymentModal}>
								{t('PAY')}
							</button>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th>№</th>
									<th>{t('ID')}</th>
									<th>{t('OWNER')}</th>
									<th>{t('SALE_POINT_NAME')}</th>
									<th className="text-center">{t('CUSTOMER_CREATOR')}</th>
									<th className="text-center">{t('DATE_OF_CREATION')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('MONTHLY_PAYMENT')}</th>
									<th className="text-center">{t('PAID_FROM')}</th>
									<th className="text-center">{t('PAID_UNTIL')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className={'tr-middle ' + ((selectedItem && selectedItem.id === item.id) ? 'table-tr-active' : '')}
										key={index} onClick={() => selectItem(item)}>
										<td>{item.rowNum}</td>
										<td>{item.id}</td>
										<td>{item.ownerLogin}</td>
										<td>{item.name}</td>
										<td>{item.managerId}</td>
										<td className="text-center">{formatDate(item.createdDate)}</td>
										<td className="text-center">{formatMoney(item.balance)}</td>
										<td className="text-center">{formatMoney(item.monthlyPayment)}</td>
										<td className="text-center">{item.paidFrom}</td>
										<td className="text-center">{item.paidUntil}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="d-flex justify-content-center" onClick={() => showPaymentHistoryModal(true, item.id)}>
													<div className="table-action-button table-action-primary-button">
														<i className="uil-file-alt"></i>
													</div>
												</div>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PAY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<span>{t('OWNER')}</span>
						<span>{selectedItem.ownerLogin}</span>
					</div>
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<span>{t('POS')}</span>
						<span>{selectedItem.name}</span>
					</div>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('PAYMENT_METHOD')}</label>
							<Select
								options={paymentTypes}
								value={paymentTypes.find(option => option.id === selectedItem.paymentMethod)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'paymentMethod': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="amountIn" autoFocus required
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amountIn': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('MONTHLY_PAYMENT')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="monthlyPayment" autoFocus required
								onChange={(e) => setSelectedItem({ ...selectedItem, 'monthlyPayment': e.target.value })} />
						</div>
						
						<div className="form-group">
							<label>{t('PAID_FROM')}<span className="required-mark">*</span></label>
							<DatePicker className="form-control"
								selected={selectedItem.paidFrom}
								dateFormat="dd.MM.yyyy"
								required="required"
								onChange={(date) => {
									setSelectedItem({ ...selectedItem, 'paidFrom': date })
								}} />		
						</div>
						<div className="form-group">
							<label>{t('PAID_UNTIL')}<span className="required-mark">*</span></label>
							<DatePicker className="form-control"
								selected={selectedItem.paidUntil}
								required="required"
								dateFormat="dd.MM.yyyy"
								onChange={(date) => {
									setSelectedItem({ ...selectedItem, 'paidUntil': date })
								}} />		
						</div>
						<div className="form-group">
							<label>{t('NOTE')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100" disabled={!selectedItem.amountIn}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* PAYMENT */}

			{/* PAYMENT HISTORY */}
			<Modal show={modalPaymentHistory} animation={false} centered size="lg"
				dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentHistoryModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('HISTORY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th>{t('TYPE')}</th>
									<th>{t('NOTE')}</th>
									<th>Создал</th>
									<th>Оплата</th>
									<th className="text-center">{t('DATE')}</th>
								</tr>
							</thead>
							<tbody>
								{payments.map((item, index) => (
									<tr key={index}>
										<td>{item.paymentTypeName}</td>
										<td>{item.note}</td>
										<td>{item.createdBy}</td>
										<td>
											<div className="text-nowrap">
												{formatMoney(item.amountIn)}
											</div>
										</td>
										<td>
											<div className="text-nowrap">
												{formatDateWithTime(item.createdDate)}
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* PAYMENT HISTORY */}
		</>
	)
}

export default Index
